import * as tslib_1 from "tslib";
import { ErrorHandler } from '@angular/core';
/**
 * Extend the default error handling to report errors to an external service - e.g Google Analytics.
 *
 * Errors outside the Angular application may also be handled by `window.onerror`.
 */
var ReportingErrorHandler = /** @class */ (function (_super) {
    tslib_1.__extends(ReportingErrorHandler, _super);
    function ReportingErrorHandler(window) {
        var _this = _super.call(this) || this;
        _this.window = window;
        return _this;
    }
    /**
     * Send error info to Google Analytics, in addition to the default handling.
     * @param error Information about the error.
     */
    ReportingErrorHandler.prototype.handleError = function (error) {
        try {
            _super.prototype.handleError.call(this, error);
        }
        catch (e) {
            this.reportError(e);
        }
        this.reportError(error);
    };
    ReportingErrorHandler.prototype.reportError = function (error) {
        if (this.window !== null && this.window.onerror !== null) {
            if (typeof error === 'string') {
                this.window.onerror(error);
            }
            else {
                this.window.onerror(error.message, undefined, undefined, undefined, error);
            }
        }
    };
    return ReportingErrorHandler;
}(ErrorHandler));
export { ReportingErrorHandler };
